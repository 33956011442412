import { IReviewModalViewProps } from '@msdyn365-commerce-modules/ratings-reviews';
import {
    IReportReviewModalViewProps,
    IReviewCardViewProps,
    IReviewsListState,
    IReviewsListViewProps
} from '@msdyn365-commerce-modules/ratings-reviews';
import { IModuleProps, Module, Node } from '@msdyn365-commerce-modules/utilities';
// import { getTelemetryObject } from '@msdyn365-commerce-modules/utilities';
// import { constructViewportInformationForImage } from '@msdyn365-commerce/core-internal';
import * as React from 'react';

const ReviewsListview: React.FC<IReviewsListViewProps> = props => {
    const {
        /* averageRating,
        filterByDropdown, */
        moduleProps,
        /* noReviewsMessage, */
        noReviewsWithFilterMessage,
        pageControls,
        /* refineReviewsProps, */
        reportReviewModal,
        /* reviewsListProps, */
        reviewCards,
        /* reviewCount, */
        reviewModal,
        /* sortByDropdown, */
        state,
        userReview
    } = props;

    const [modalOpen, setModalOpen] = React.useState(false);
    // const telemetryContent = getTelemetryObject(props.context.request.telemetryPageName ?? '', 'Recensioni', props.telemetry);

    const _toggleReviewModal = () => {
        document.body.style.overflowY = modalOpen ? 'scroll' : 'hidden';
        setModalOpen(!modalOpen);
    };

    // @ts-ignore
    const averageRating = props.data.ratingsSummary.result.averageRating ?? 0;
    // @ts-ignore
    const buttonLabel = props.resources.product_review_list_button_label ?? '';
    // @ts-ignore
    const title = props.resources.reviewListLabel ?? '';

    React.useEffect(() => {
        const page_summary = document.getElementsByClassName('msc-average-rating-container')[0];
        const modal_summary = document.getElementsByClassName('msc-average-rating-container')[1];
        if (page_summary && modal_summary) {
            modal_summary.innerHTML = page_summary.innerHTML;
        }
    });

    if (!userReview && reviewCards.length === 0 && !state.isFilterApplied) {
        /* return  <Module {...moduleProps}>{noReviewsMessage}</Module>; */
        /* return (
        <Module {...moduleProps}>
            <div className="msc-average-rating-container"></div>
        </Module>); */
        return null;
    }

    return (
        <Module {...moduleProps}>
            {/* {averageRating}
            {reviewCount} */}
            {/* {   reviewCards.length === 0 ?
                null : <Node {...refineReviewsProps} >
                    {sortByDropdown}
                    {filterByDropdown}
                </Node>
            } */}
            {/* {React.createElement(React.Fragment, null,
            modalToggle,
            createReviewModal(reviewModal, moduleProps)))} */}
            {/* <Node {...reviewsListProps}>
                {userReview && buildReviewCard(userReview)}
                {reviewCards.map(review => {
                    return buildReviewCard(review);
                })}
            </Node> */}
            <div className='modal' onClick={_toggleReviewModal} style={{ display: modalOpen ? 'block' : 'none' }}>
                <div className='modal-content' onClick={e => e.stopPropagation()}>
                    <div className='modal-header'>
                        <span className='title'>{title}</span>
                        <button className='msc-modal__close-button' onClick={_toggleReviewModal}></button>
                    </div>
                    <div className='msc-average-rating-container modal-summary'></div>
                    <div className='list-container'>
                        <div className='list-wrapper'>
                            {userReview && buildReviewCard(userReview)}
                            {reviewCards.map(review => {
                                return buildReviewCard(review);
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <button className='show-reviews' onClick={_toggleReviewModal}>
                {buttonLabel}
            </button>
            {reviewCards.length === 0 && state.isFilterApplied && noReviewsWithFilterMessage}
            {pageControls}
            {createReviewModal(reviewModal, moduleProps)}
            {createReportModal(reportReviewModal, state)}
        </Module>
    );
};

const buildReviewCard = (props: IReviewCardViewProps) => {
    return (
        <Node {...props.cardProps}>
            <Node {...props.headerProps}>
                {props.name}
                {props.date}
                {props.rating}
            </Node>
            <Node {...props.cardBodyProps}>
                <Node {...props.reviewProps}>
                    {/* {props.reviewTitle} */}
                    {props.reviewText}
                </Node>
                <Node {...props.responseProps}>
                    {props.responseName}
                    {props.responseDate}
                    {props.responseText}
                </Node>
                <Node {...props.controlsProps}>
                    {/* {props.ratingHelpfulLabel}
                    {props.like}
                    {props.dislike} */}
                    {props.edit}
                    {/* {props.report} */}
                </Node>
            </Node>
        </Node>
    );
};

const createReviewModal = (props: IReviewModalViewProps, moduleProps: IModuleProps): JSX.Element => {
    return (
        <Module {...props.modal} {...moduleProps}>
            {props.modalHeader}
            <Node {...props.modalBody}>
                <Node {...props.form}>
                    <Node {...props.inputRow}>
                        {props.rating}
                        {props.ratingLabel}
                    </Node>
                    <Node {...props.inputRow}>
                        {props.titleLabel}
                        {props.titleInput}
                    </Node>
                    <Node {...props.inputRow}>
                        {props.textLabel}
                        {props.textInput}
                    </Node>
                    {props.privacyPolicyUrl}
                    {props.error}
                </Node>
            </Node>
            <Node {...props.modalFooter}>
                {props.submitButton}
                {props.cancelButton}
            </Node>
        </Module>
    );
};

const createReportModal = (props: IReportReviewModalViewProps, state: IReviewsListState): JSX.Element => {
    return (
        <Node {...props.modal}>
            <Node {...props.modalHeader}>{state.reported ? props.headerSubmitted : props.header}</Node>
            <Node {...props.modalBody}>
                {state.reported ? props.reportSubmittedMessage : [props.reportMessage, props.radioButtons, props.error]}
            </Node>
            <Node {...props.modalFooter}>{state.reported ? props.succesfulButton : [props.submitButton, props.cancelButton]}</Node>
        </Node>
    );
};

export default ReviewsListview;
